import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-business',
  templateUrl: './modern-sass-business.component.html',
  styleUrls: ['./modern-sass-business.component.scss']
})
export class ModernSassBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  business = [
    {
      title:'Create work opportunities',
      description:'Due to the devistating loss of jobs over the past few years because of the pandemic.'
    },
    {
      title:'Post Covid travel',
      description:'More and more countries are softening their travel restrictions, thus we are expecting a slow and steady increase in both local and international travel.'
    },
    {
      title:'Work from home or remotely',
      description:'With the TFM platform, you can setup and work from the comfort of your own home while building your business.'
    }
  ]
}
