<app-modern-sass-nav></app-modern-sass-nav>

<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}" style="height: 50vh;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="center-text">
                            <div class="">
                                <!-- <div class="header-text">
                                    <h1 style="text-align: center;">The ideal platform</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="saas2-sub-text" style="text-align: center;">for <span class="bold-text">Travellers </span>& 
                                      <span class="bold-text">Agencies</span>
                                    </h3>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white" style="text-align: center;">Our aim is to advance the travel industry when it comes to the use of technology.  We strive to "do away" with the separation 
                                        between traveler and agent by ensuring the tools exist for a smoother booking 
                                        and travel experience.</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  
            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->

<!--feature css start-->
<section class="saas2 feature booking" id="feaure" style="padding-bottom: 0px;">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Register for your TFM turn key solution</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">Travel Find Me strives to help you go from travel business 
                            idea to having an up and running travel business in the most efficient time and 
                            cost as possible.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </section>
<!--feature css end-->



<!-- section start -->
<section class="register-page section-b-space" style="padding-top: 0px;">
<div class="container">
  <div class="row">
      <div class="col-lg-12">
        <ul ngbNav #navRegister="ngbNav" class="nav" [(activeId)]="tabIndex">
            <li [ngbNavItem]="1" class=""><a ngbNavLink [class.active]="tabIndex==1"><h3>create account</h3></a>
                <ng-template ngbNavContent>
                    <div class="theme-card">
                        <form class="theme-form">
                            <div class="form-row">
                                <div class="col-md-6">
                                    <label for="email">First Name</label>
                                    <input type="text" class="form-control" id="fname" placeholder="First Name" required="">
                                </div>
                                <div class="col-md-6">
                                    <label for="review">Last Name</label>
                                    <input type="password" class="form-control" id="lname" placeholder="Last Name" required="">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" id="email" placeholder="Email" required="">
                                </div>
                                <div class="col-md-6">
                                    <label for="review">Password</label>
                                    <input type="password" class="form-control" id="review" placeholder="Enter your password" required="">
                                </div>
                                <button class="btn primary-btn btn-default radius-0" (click)="nextStep()">Next</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" class=""><a ngbNavLink [class.active]="tabIndex==2"><h3>Basic Info</h3></a>
                <ng-template ngbNavContent>
                    <div class="theme-card">
                        <form class="theme-form">
                            <div class="form-row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Website Name</label>
                                        <input type="text" class="form-control" placeholder="Website Name" [(ngModel)]="site.name">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Website Url</label>
                                        <input type="text" class="form-control" placeholder="Website Url" [(ngModel)]="site.website">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Address</label>
                                        <input type="text" class="form-control" placeholder="Address" [(ngModel)]="site.address">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Phone Number</label>
                                        <input type="tel" class="form-control" placeholder="Number" [(ngModel)]="site.telephone">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">City</label>
                                        <input type="text" class="form-control" placeholder="City">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-3">
                                    <div class="form-group">
                                        <label class="form-label">Postal Code</label>
                                        <input type="number" class="form-control" placeholder="ZIP Code">
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label class="form-label">Country</label>
                                        <input type="number" class="form-control" placeholder="Country">
                                    </div>
                                </div>
                                <button class="btn primary-btn btn-default radius-0" (click)="nextStep()">Next</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" class=""><a ngbNavLink [class.active]="tabIndex==3"><h3>Social Media</h3></a>
                <ng-template ngbNavContent>
                    <div class="theme-card">
                        <form class="theme-form">
                            <div class="form-row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Facebook</label>
                                        <input type="text" class="form-control" placeholder="https://www.facebook.com/" [(ngModel)]="site.socialUrlFacebook">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Instagram</label>
                                        <input type="text" class="form-control" placeholder="https://www.instagram.com/" [(ngModel)]="site.socialUrlInstagram">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Twitter</label>
                                        <input type="text" class="form-control" placeholder="https://twitter.com/" [(ngModel)]="site.socialUrlTwitter">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Pinterest</label>
                                        <input type="text" class="form-control" placeholder="https://in.pinterest.com/" [(ngModel)]="site.socialUrlPinterest">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">LinkedIn</label>
                                        <input type="text" class="form-control" placeholder="https://linkedin.com/" [(ngModel)]="site.socialUrlLinkedIn">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Tiktok</label>
                                        <input type="text" class="form-control" placeholder="https://tiktok.com/" [(ngModel)]="site.socialUrlTikTok">
                                    </div>
                                </div>
                                <button class="btn primary-btn btn-default radius-0" (click)="nextStep()">Next</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="4" class=""><a ngbNavLink [class.active]="tabIndex==4"><h3>Payment</h3></a>
                <ng-template ngbNavContent>
                    <div class="theme-card">
                        <form class="theme-form">
                            <div class="form-row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Facebook</label>
                                        <input type="text" class="form-control" placeholder="https://www.facebook.com/" [(ngModel)]="site.socialUrlFacebook">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Instagram</label>
                                        <input type="text" class="form-control" placeholder="https://www.instagram.com/" [(ngModel)]="site.socialUrlInstagram">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Twitter</label>
                                        <input type="text" class="form-control" placeholder="https://twitter.com/" [(ngModel)]="site.socialUrlTwitter">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Pinterest</label>
                                        <input type="text" class="form-control" placeholder="https://in.pinterest.com/" [(ngModel)]="site.socialUrlPinterest">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">LinkedIn</label>
                                        <input type="text" class="form-control" placeholder="https://linkedin.com/" [(ngModel)]="site.socialUrlLinkedIn">
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Tiktok</label>
                                        <input type="text" class="form-control" placeholder="https://tiktok.com/" [(ngModel)]="site.socialUrlTikTok">
                                    </div>
                                </div>
                                <button class="btn primary-btn btn-default radius-0">Register</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </li>
            <!-- <li [ngbNavItem]="5" class=""><a ngbNavLink><h3>Website Theme</h3></a>
                <ng-template ngbNavContent>
                    <div class="theme-card">
                        <form class="theme-form">
                            <div class="form-row">
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <div class="form-group">
                                        <label class="form-label">Primary Colour</label>
                                        <input [style.background]="site.colorPrimary" [(colorPicker)]="site.colorPrimary" [value]="site.colorPrimary" />
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <div class="form-group">
                                        <label class="form-label">Secondary Colour</label>
                                        <input [style.background]="site.colorSecondary" [(colorPicker)]="site.colorSecondary" [value]="site.colorSecondary"/>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4 col-sm-4">
                                    <div class="form-group">
                                        <label class="form-label">Theme Toggle</label>
                                        <label class="custom-switch">
                                            <input type="checkbox" [(ngModel)]="siteTheme" (click)="updateTheme()" name="custom-switch-checkbox" class="custom-switch-input">
                                            <span class="custom-switch-indicator"></span>
                                            <span class="custom-switch-description">{{siteTheme?'Dark':'Light'}}</span>
                                        </label>
                                    </div>
                                </div>
                                <button class="btn primary-btn btn-default radius-0">Next</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </li> -->
        </ul>
        <div [ngbNavOutlet]="navRegister" class="tabs-menu-body p-0"></div>
        
          
      </div>
  </div>
</div>
</section>
<!--Section ends-->

<app-modern-sass-footer></app-modern-sass-footer>