import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
	 {
      path: '/', title: 'Home', type: 'main', children: [
         // { path: '/agency', title: 'Agency', type: 'link' },
      ],
    },
    { path: '/getting-started', title: 'Getting Started', type: 'main', children: [] },
    { path: '/about', title: 'About', type: 'main', children: [] },
    // { title: 'Blog', type: 'main', children: [] },
    // { title: 'Features', type: 'main', children: [] },
    { title: 'Services', type: 'sub', children: [
      { path: '/starter', title: 'Starter Solution', type: 'link' },
      { path: '/premium', title: 'Premium Solution', type: 'link' },
    ] },
    {path: '/affiliate', title: 'Affiliate Program', type: 'main', children: [] },
    {path: '/contact', title: 'Contact Us', type: 'main', children: [] },
   ]
  
  	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
   
}
