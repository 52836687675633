import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedLayout, ButtonsConfig, ButtonsStrategy, Image, KS_DEFAULT_BTN_CLOSE, KS_DEFAULT_BTN_DELETE, KS_DEFAULT_BTN_DOWNLOAD, KS_DEFAULT_BTN_EXTURL, KS_DEFAULT_BTN_FULL_SCREEN, PlainGalleryConfig, PlainGalleryStrategy } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-media-pack',
  templateUrl: './media-pack.component.html',
  styleUrls: ['./media-pack.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaPackComponent implements OnInit {
  allImages: Image[] = [
    new Image(0, { img: 'assets/images/portfolio/1.jpg' }),
    new Image(0, { img: 'assets/images/portfolio/2.jpg' }),
    new Image(1, { img: 'assets/images/portfolio/3.jpg' }),
    new Image(2, { img: 'assets/images/portfolio/5.jpg' }),
    new Image(3, { img: 'assets/images/portfolio/4.jpg' }),
    new Image(4, { img: 'assets/images/portfolio/6.jpg' }),
    new Image(5, { img: 'assets/images/portfolio/7.jpg' }),
    new Image(6, { img: 'assets/images/portfolio/8.png' }),
    new Image(7, { img: 'assets/images/portfolio/9.jpg' }),
  ];

  constructor(private route: ActivatedRoute,
    private title: Title, private modalService: NgbModal) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }

  openModal(modal) {
    this.modalService.open(modal, { size: 'xl', scrollable: true });
  }

  features = [
    {
      img:'assets/images/saas2/advance-feature/6.png',
      title: 'Honesty',
      description: 'We built the solution with an honest attempt to improve the travel experience.  We have tried to incorporate this value into the solution by providing accurate and honest reporting across the solution.'
    },
    {
      img:'assets/images/saas2/advance-feature/7.png',
      title: 'Trust',
      description: 'We believe that no partnership can work without trust.  We have therefore incorporated various methods within the solution to ensure that trust is shared by agency, agent and traveler.'
    },
    {
      img:'assets/images/saas2/advance-feature/8.png',
      title: 'Kindness',
      description: 'We hope that our solution will allow you, as agent and traveler, to form a lasting and kind relationship with each other as well as with us.'
    },
    {
      img:'assets/images/saas2/advance-feature/9.png',
      title: 'Safety',
      description: 'We have build our solution in such a way that safety of all is considered by protecting the data stored and exchanged in encrypted format.'
    }
  ]

  business = [
    {
      title:'OUR MISSION',
      description:'Our mission is to simplify travel, for the agent, agency and traveler. Keeping the agent and traveler connected through the whole booking experience.'
    },
    {
      title:'OUR VISION',
      description:'Our vision is to allow travelers to each have their own dedicated travel agent in their pocket by combining the latest technology with mobile devices.'
    }
  ]
}
