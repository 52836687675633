import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModernSassComponent } from './layouts/modern-sass/modern-sass.component'
import { from } from 'rxjs';

import { GettingStartedComponent } from './layouts/modern-sass/pages/getting-started/getting-started.component';
import { RegisterComponent } from './layouts/modern-sass/pages/register/register.component';
import { AboutComponent } from './layouts/modern-sass/pages/about/about.component';
import { MediaPackComponent } from './layouts/modern-sass/pages/media-pack/media-pack.component';
import { AffiliateComponent } from './layouts/modern-sass/pages/affiliate/affiliate.component';
import { StarterComponent } from './layouts/modern-sass/pages/starter/starter.component';
import { PremiumComponent } from './layouts/modern-sass/pages/premium/premium.component';
import { ContactComponent } from './layouts/modern-sass/pages/contact/contact.component';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'modern-sass',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: ModernSassComponent,
    data: {
      title: "Travel Find Me | Let the journey find you"
    }
  },
  {
    path: 'getting-started',
    component: GettingStartedComponent,
    data: {
      title: "Getting started with Travel Find Me"
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: "Getting started with Travel Find Me"
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      title: "About Travel Find Me"
    }
  },
  {
    path: 'media-pack',
    component: MediaPackComponent,
    data: {
      title: "Media Pack for Travel Find Me"
    }
  },
  {
    path: 'affiliate',
    component: AffiliateComponent,
    data: {
      title: "The TFM Affiliate Program"
    }
  },
  {
    path: 'starter',
    component: StarterComponent,
    data: {
      title: "The TFM Starter Program"
    }
  },
  {
    path: 'premium',
    component: PremiumComponent,
    data: {
      title: "The TFM Premium Program"
    }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      title: "Get in contact with us"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
