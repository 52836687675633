<app-modern-sass-nav></app-modern-sass-nav>
<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png?v=1)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="center-text">
                            <div class="">
                                <div class="header-text">
                                    <h1>TFM Starter</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="saas2-sub-text">the <span class="bold-text">perfect solution </span>for
                                      <span class="bold-text">independent agents</span>
                                    </h3>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white">Ever thought of becoming a travel agent or even owning your own agency with travel agents working for
                                        you?<br><br>
                                    </p>
                                    <p class="sub-para text-white">With the TFM starter solution you can step into your own brand new travel agent business, using the
                                        TFM turn key business solutions.
                                    </p>
                                </div>
                                <a class="btn btn-default primary-btn transparent" routerLink="/register">start today</a>
                            </div>
                        </div>
                    </div>
                    <div class="center-text set-abs">
                        <img alt="" src="assets/images/saas2/starterheader.png" class="img-fluid img-dextop">
                    </div>
                </div>
            </div>
  
            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->

<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>TFM Starter Turn Key Solution</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">The ideal turn key solution to take you from travel business idea to having an up and running travel business in the
                            most efficient time and cost possible. In order to make your business setup process as quickly as possible,<br>
                            here are the key items you will need.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-md-7">
                <div class="center-text justify-content-center">
                    <div class="image-container">
                        <img src="assets/images/saas2/advance-feature/04.png" alt=""
                             class="img-fluid advance-feature-img">
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-5">
                <div *ngFor="let data of features">
                    <div class="center-content justify-content-start">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="feature-content">
                            <h5 class="feature-head">{{data.title}}</h5>
                            <p class="feature-para">{{data.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature css end-->

<app-modern-sass-business></app-modern-sass-business>

<!--faq css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">Just like any new business venture if you didn't have any 
                            questions, we'd be a little bit worried! Here are a few key questions you might 
                            have...
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="faq-block">
                    <div>
                        <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                            <ngb-panel id="static-1" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    Do I need a website?
                                </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    Yes, in order to have a fully functional independent agent business up and running! You will need a website where you can display
                                    your chosen traveling packages and destinations. But don't stress, the TFM Starter Solution takes care of all that for you. Once you're
                                    registered as a TFM platform user of the starter solution your auto generated website should be up and running, ready to take enquiries 
                                    within a month.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    What will the starter solution cost me?
                                </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    The TFM starter solution will cost you R2,495.00/month, with a once off setup fee of R9,995.00. If you have all your needed content 
                                    and information as mentioned at the top of this page under the TFM Starter turn key solution section, you can have your website up 
                                    and running ready to take enquiries within 1 month of making your first payment.
                                </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-3" class="card">
                                    <ng-template ngbPanelTitle class="card-header">
                                        <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        What if I don't have all the information needed?
                                    </div>
                                    </ng-template>
                    
                                    <ng-template ngbPanelContent class="card-body">
                                        No worries! If you don't have any of the needed content or information needed like a register domain name or a logo and colours, 
                                        all this means is that you will need to get that sorted before rolling out your brand new travel website, which could delay the 
                                        website from taking enquiries. We would be glas to assist you with this with our add-on services.
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-4" class="card">
                                    <ng-template ngbPanelTitle class="card-header">
                                        <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        How do I sign up for the starter solution?
                                    </div>
                                    </ng-template>
                    
                                    <ng-template ngbPanelContent class="card-body">
                                        We are super excited that you want to start using the TFM starter solution!<br>
                                        <!-- <a routerLink="/register">Please click here to register.</a> -->
                                        <a routerLink="/contact">Contact us to get started.</a>
                                    </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="faq-img-block">
                    <img src="assets/images/saas2/faq.png" alt=""
                    class="img-fluid advance-feature-img">
                </div>
            </div>
        </div>
    </div>
</section>
<!--faq css end-->

<!-- <app-modern-sass-testimonial></app-modern-sass-testimonial> -->

<app-modern-sass-brand></app-modern-sass-brand>
<app-modern-sass-footer></app-modern-sass-footer>