import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public tabIndex: number=1;

  constructor() { }

  ngOnInit() {
  }

  nextStep(){
    this.tabIndex++;
  }

}
