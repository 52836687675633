import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { TFMService } from '../../../../shared/service/tfm.service';

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AffiliateComponent implements OnInit {

  public formStatus : any = {
    message:"",
    errors:false,
    success:false
  };

  constructor(private tfmService:TFMService, private route: ActivatedRoute,
    private title: Title) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }

  onClickSubmit(data:NgForm) {
    this.formStatus.message = "";
    this.formStatus.success = false;
    this.formStatus.error = false;

    if (data.valid){

      var emailMessage = "";

      emailMessage += "<table>";
      emailMessage += "<tr><td>First Name:</td><td>" + data.value.firstName + "</td></tr>";
      emailMessage += "<tr><td>Last Name:</td><td>" + data.value.lastName + "</td></tr>";
      emailMessage += "<tr><td>Phone:</td><td>" + data.value.phone + "</td></tr>";
      emailMessage += "<tr><td>Email:</td><td>" + data.value.email + "</td></tr>";
      
      emailMessage += "<tr><td colspan='2'><b>Platforms</b></td></tr>";
      emailMessage += "<tr><td>Facebook:</td><td>" + data.value.facebook + "</td></tr>";
      emailMessage += "<tr><td>Instagram:</td><td>" + data.value.instagram + "</td></tr>";
      emailMessage += "<tr><td>Pinterest:</td><td>" + data.value.pinterest + "</td></tr>";
      emailMessage += "<tr><td>TikTok:</td><td>" + data.value.tiktok + "</td></tr>";
      emailMessage += "<tr><td>YouTube:</td><td>" + data.value.youtube + "</td></tr>";
      emailMessage += "<tr><td>Blogging:</td><td>" + data.value.blogging + "</td></tr>";
      emailMessage += "<tr><td>Other:</td><td>" + data.value.other + " | " + data.value.othertext + "</td></tr>";

      emailMessage += "<tr><td colspan='2'>&nbsp;</td></tr>";
      emailMessage += "<tr><td>Message:</td><td>" + data.value.message + "</td></tr>";
      emailMessage += "<table>";
    
      this.tfmService.sendEmail(data.value.name, "info@travelfind.me", "Affiliate Request", emailMessage).subscribe(res => {
        this.formStatus.message = "Message sent, we will get back to you soon!";
        this.formStatus.success = true;
      });
    }
    else {
      this.formStatus.message = "Please complete all fields";
      this.formStatus.error = true;
    }
  }

  features = [
    {
      img:'assets/images/saas2/advance-feature/10.png',
      title: 'Ready made packages',
      description: 'Get access to ready made travel packages and deals. You simply need to share these with your current followers to be able to earn commision'
    },
    {
      img:'assets/images/saas2/advance-feature/11.png',
      title: 'Earn Commision',
      description: 'Whenever any of your followers book one of the packages that you shared, on any of our partner sites, you will earn a commision'
    },
    {
      img:'assets/images/saas2/advance-feature/12.png',
      title: 'Tracking Data',
      description: 'On the TFM platform, you will be able to track your sales as well as earnings'
    },
    {
      img:'assets/images/saas2/advance-feature/9.png',
      title: 'Safety',
      description: 'We have build our solution in such a way that safety of all is considered by protecting the data stored and exchanged in encrypted format.'
    }
  ]

  business = [
    {
      title:'OUR MISSION',
      description:'Our mission is to simplify travel, for the agent, agency and traveler. Keeping the agent and traveler connected through the whole booking experience.'
    },
    {
      title:'OUR VISION',
      description:'Our vision is to allow travelers to each have their own dedicated travel agent in their pocket by combining the latest technology with mobile devices.'
    }
  ]
}
