import { Injectable } from '@angular/core';

@Injectable()
export class Configuration {
 
    public _local : boolean = false;
    public _debug : boolean = false;
 
    public _dev : boolean = false;
    public _bookingEngine : boolean = false;
    
    public _itinIdentifier : string = "";
    public _userGuidIdentifier : string = "";
    public _userIdIdentifier : string = "";
    public _tokenIdentifier : string = "";
    public _searchesIdentifier : string = "";
    public _searchDateTime : string = "";

    public _showPerItemPrices : boolean = false;
    
    constructor() {
        if (this._local) {
            this.TFMApiLink.href = "http://localhost:64450/";
            this.TFMApiEntryLink.href = "http://localhost:64450/entry";
        } 
        else {
            this.TFMApiLink.href = "https://api.travelfind.me/";
            this.TFMApiEntryLink.href = "https://api.travelfind.me/entry";
        }
        this.TFMApiEntryLink.method = "get";

        this._itinIdentifier = "itin" + this.TFMApiAgencyID + "-" + this.TFMApiSiteID;
        this._userGuidIdentifier = "userGuid" + this.TFMApiAgencyID + "-" + this.TFMApiSiteID;
        this._userIdIdentifier = "userId" + this.TFMApiAgencyID + "-" + this.TFMApiSiteID;
        this._tokenIdentifier = "token" + this.TFMApiAgencyID + "-" + this.TFMApiSiteID;
        this._searchesIdentifier = "searches" + this.TFMApiAgencyID + "-" + this.TFMApiSiteID;
        this._searchDateTime = "searchDateTime" + this.TFMApiAgencyID + "-" + this.TFMApiSiteID;
    }

    public TFMApiLink: any = {};
    public TFMApiEntryLink: any = {};
    public TFMApiAgencyID: number = 0;
    public TFMApiSiteID: number = 0;
}