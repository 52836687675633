import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-services',
  templateUrl: './modern-sass-services.component.html',
  styleUrls: ['./modern-sass-services.component.scss']
})
export class ModernSassServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  services = [
    {
      img:'assets/images/saas2/our-feature/1.png',
      title:'Instant bookings',
      description:'Traveller - Find tailored travel packages or get notified when your ideal package becomes available.'
    },
    {
      img:'assets/images/saas2/our-feature/2.png',
      title:'Your own travel agent',
      description:'Traveller - Have quick and easy access to your own personal travel agent that knows your likes and dislikes.'
    },
    {
      img:'assets/images/saas2/our-feature/3.png',
      title:'Crowdfunding',
      description:'Traveller - Create your ideal holiday, whether a weekend or a honeymoon and allow your family and friends to contribute.'
    },
    {
      img:'assets/images/saas2/our-feature/4.png',
      title:'Always available',
      description:'Agents - Be there for your client with real-time & instant notifications to your mobile device.'
    },
    {
      img:'assets/images/saas2/our-feature/5.png',
      title:'Easy access',
      description:'Agents - Access to all your bookings right on your mobile device to quickly assist your client or delegate booking assistance to a co-worker.'
    },
    {
      img:'assets/images/saas2/our-feature/6.png',
      title:'Reporting & stats',
      description:'Agents - See real-time reporting and stats on your visits and engagements with your customers.  From impression to conversion.'
    }
  ]
}
