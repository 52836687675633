import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GettingStartedComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private title: Title) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }

  features = [
    {
      img:'assets/images/saas2/advance-feature/1.png',
      title: 'Website Integration',
      description: 'If you don\'t have a website yet our development team can help you to get your website up and running. If you do have a website already we will merely connect your current website with our platform'
    },
    {
      img:'assets/images/saas2/advance-feature/2.png',
      title: 'Online Reservation System',
      description: 'With the online reservation system you have two options available. You can either work with enquiry only bookings or we can set up your reservation system to enable clients to do instant bookings, making the booking process faster and simpler'
    },
    {
      img:'assets/images/saas2/advance-feature/3.png',
      title: 'Various Supplier APIs.',
      description: 'We already have access to various flight, hotel, car rental and transfer/shuttle service APIs. You have access to our ready-built APIs (based on your TFM service plan), however we can also help you integrate your own supplier APIs through the TFM platform'
    },
    {
      img:'assets/images/saas2/advance-feature/5.png',
      title: 'Mobile App - Coming Soon',
      description: 'Once the TFM mobile app launces you will, depending on your chosen TFM service plan, gain access to some or all the features that the mobile app offers.'
    }
  ]

}
