<!--brand css start-->
<section class="saas2 brand">
    <div class="col-md-10 offset-md-1 col-12">
        <div class="container saas2-services">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="title">
                        <div class="main-title">
                            <h2>Our valued partnerships and business friendships</h2>
                        </div>
                        <div class="sub-title">
                            <p class="sub-title-para">We value our business friendships and partners in travel
                                 and adventures. We believe in building lasting relationships in business and
                                  life.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <owl-carousel-o [options]="brandcarouselOptions" class="brand-slider">
                        <ng-container *ngFor="let brand of brands">
                            <ng-template carouselSlide class="item">
                                <a href="javascript:void(0)">
                                    <img [src]="brand.img" alt="" class="img-fluid">
                                </a>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>
<!--brand css end-->