<app-modern-sass-nav></app-modern-sass-nav>
<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="center-text">
                            <div class="">
                                <div class="header-text">
                                    <h1>Contact Us</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="saas2-sub-text">make <span class="bold-text">Contact </span>with
                                      <span class="bold-text">Us</span>
                                    </h3>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white">Would you like to enquiry about any of our services or have a question regarding
                                        travel technology etc. Please get in contact with us, we'd love to hear from you.
                                    </p>
                                </div>
                                <!-- <a class="btn btn-default primary-btn transparent" routerLink="/getting-started">start now</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="center-text set-abs">
                        <img alt="" src="assets/images/saas2/contactheader.png" class="img-fluid img-dextop" style="padding-top: 30px;">
                    </div>
                </div>
            </div>
  
            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->
  
<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">If you have a question, here are some of the frequently asked questions. Please contact us if you still have questions, we are happy to help!
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="faq-block">
                    <div>
                        <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                            <ngb-panel id="static-1" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    Do I need a new website?
                                </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    If you already have a website up and running, we can simply connect your website 
                                    with the TFM platform and get you ready to start using it. If you don't have a website
                                    up and running that can accomodate the features of the TFM platform, we can get a basic
                                    website up and running for you in a jiffy!
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    How quickly will I be able to be up and running?
                                </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    Typically we are able to get our clients connected to the TFM platform within 4 to 6 weeks.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-3" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    Do you offer help with internet marketing?
                                </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    We do have internet marketing add-on services that we can help you with. 
                                    These will include assistance with:<br>
                                    <ul class="m-0">
                                        <li><i class="fa fa-angle-double-right mr-2"></i>Search Engine Optimisation</li>
                                        <li><i class="fa fa-angle-double-right mr-2"></i>Content Marketing</li>
                                        <li><i class="fa fa-angle-double-right mr-2"></i>Social Meida Marketing</li>
                                        <li><i class="fa fa-angle-double-right mr-2"></i>Email Marketing</li>
                                        <li><i class="fa fa-angle-double-right mr-2"></i>Affiliate Marketing</li>
                                    </ul>
                                </ng-template>
                            </ngb-panel>
                            <!-- <ngb-panel id="static-4" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    Do I need to make a payment?
                                </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    No one cares about products. People care about ideas. Is a product an idea?
                                    Noup. Is
                                    a brand? A good one is.
                                </ng-template>
                            </ngb-panel> -->
                        </ngb-accordion>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="faq-img-block">
                    <img src="assets/images/saas2/faq.png" alt=""
                    class="img-fluid advance-feature-img">
                </div>
            </div>
        </div>
    </div>
</section>
 <!--feature css end-->

<!--contact css start-->
<section class="event contact set-relative bg bg-img bg-about p-b-0 bg-gradient" id="contact">
    <div class="container p-b-100">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white">Let us hear from you</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-white"><span>So,</span> you want to get in contact with us!?<br>
                            <span>Awesome,</span> we love to hear from you.<br>
                            Simply complete the from below to get in contact with us.</P>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-md-6 p-r-0 map">
                <div class="iframe-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53025.94831239799!2d18.465521149013078!3d-33.83497020459018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5edf9279f85b%3A0x2da7083669364eb5!2sBloubergstrand%2C%20Cape%20Town%2C%207441!5e0!3m2!1sen!2sza!4v1629386474514!5m2!1sen!2sza" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
            <div class="col-xl-5 p-l-0 col-md-6 set-z-index form-footer text-left">
                <div class="bg-white">
                    <form #frmContactUs="ngForm" (ngSubmit)="onClickSubmit(frmContactUs)">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="name">Name *</label>
                                    <input type="text" class="form-control" name="name" ngModel required>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Phone">Phone</label>
                                    <input type="tel" class="form-control" name="phone" ngModel>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Email">Email *</label>
                                    <input type="email" class="form-control" name="email" ngModel required>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Subject">Subject *</label>
                                    <input type="text" class="form-control" name="subject" ngModel required>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Message">Message *</label>
                            <input type="text" class="form-control" name="message" ngModel required>
                        </div>

                        <div class="col-md-12 text-center">
                            <input type="submit" class="btn btn-default primary-btn m-0-auto" value="Send"/>
                            <span class="text-danger mt-2" *ngIf="formStatus.error"><br/><br/>{{formStatus.message}}</span>
                            <span class="text-success mt-2" *ngIf="formStatus.success"><br/><br/>{{formStatus.message}}</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--testimonial css end-->



<app-modern-sass-brand></app-modern-sass-brand>
<app-modern-sass-footer></app-modern-sass-footer>