<app-modern-sass-nav></app-modern-sass-nav>
<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div style="padding-top: 25px;" class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="center-text">
                            <div class="">
                                <div class="header-text">
                                    <h1>Affiliate Program</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="saas2-sub-text">make <span class="bold-text">Travel </span>your
                                      <span class="bold-text">Career</span>
                                    </h3>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white">Do you love to travel? These days doing 
                                        what you love as a job is all the hype!!! If you've ever wondered 
                                        whether you could make a career out of your travel passion then 
                                        keep reading to find out more.
                                    </p>
                                </div>
                                <!-- <a class="btn btn-default primary-btn transparent" routerLink="/getting-started">start now</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="center-text set-abs">
                        <img alt="" src="assets/images/saas2/affiliateheader.png" class="img-fluid img-dextop">
                    </div>
                </div>
            </div>
  
            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->

<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Affiliate Program Membership and features</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">If you already have a significant online following that knows 
                            about your passion for travel and adventure, our TFM affiliate program might 
                            just be what you need to monetize on your travel passion! Below are a few key 
                            features of the program.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-md-7">
                <div class="center-text justify-content-center">
                    <div class="image-container">
                        <img src="assets/images/saas2/advance-feature/04.png" alt=""
                             class="img-fluid advance-feature-img">
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-5">
                <div *ngFor="let data of features">
                    <div class="center-content justify-content-start">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="feature-content">
                            <h5 class="feature-head">{{data.title}}</h5>
                            <p class="feature-para">{{data.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature css end-->

<app-modern-sass-business></app-modern-sass-business>

<!--faq css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">Just like any new business venture if you didn't have any 
                            questions, we'd be a little bit worried! Here are a few key questions you might 
                            have...
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="faq-block">
                    <div>
                        <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                            <ngb-panel id="static-1" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    Do I need a website?
                                </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    The short answer is No! You don't need a website to become a TFM affiliate 
                                    member. As long as you have an online presence you can 
                                    become a TFM affiliate. However, having a website with a following could 
                                    definitely help.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                    <a>
                                        <div class="fa fa-angle-right rotate"></div>
                                    </a>
                                    What will it cost me to become an affiliate?
                                </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    You won't have to pay anything in order to become a TFM affiliate! However, 
                                    just like any other business opportunity some capital to use as initial 
                                    investment or as marketing budget is favourable.
                                </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-3" class="card">
                                    <ng-template ngbPanelTitle class="card-header">
                                        <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        Do I need previous experience in the travel industry?
                                    </div>
                                    </ng-template>
                    
                                    <ng-template ngbPanelContent class="card-body">
                                        No! Previous experience in the travel industry is not a requirment in 
                                        order to become an affiliate member. If you however do have some 
                                        experience from travel that would be an advantage.
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-4" class="card">
                                    <ng-template ngbPanelTitle class="card-header">
                                        <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        How do I become a TFM affiliate member?
                                    </div>
                                    </ng-template>
                    
                                    <ng-template ngbPanelContent class="card-body">
                                        We are super excited that you are considering to register as a TFM 
                                        affiliate member! All you have to do it scroll down to the bottom of 
                                        this page and complete the affilate member request form.
                                    </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="faq-img-block">
                    <img src="assets/images/saas2/faq.png" alt=""
                    class="img-fluid advance-feature-img">
                </div>
            </div>
        </div>
    </div>
</section>
<!--faq css end-->


<!--contact css start-->
<section class="event contact music set-relative bg bg-img bg-about p-b-0 bg-gradient" id="contact">
    <div class="container p-b-100">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class="text-white">Affiliate Member Request Form</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-white">Before we can register you as a <span>TFM affiliate member,</span> 
                            we need some information so that we can offer you the appropriate program and advice.<br>
                            Please complete the form below so we can send you an <span>affiliate program proposal </span>
                            for your consideration.
                        </P>
                    </div>
                </div>
            </div>
        </div>
        <div class="typo-content text-white">
            <form #frmAffiliate="ngForm" (ngSubmit)="onClickSubmit(frmAffiliate)">
                <div class="form-row">
                    <div class="col-md-10">
                        <h3 class="text-white">Please provide your personal information</h3><br>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="firstName">First Name *</label>
                        <input type="text" class="form-control" name="firstName" placeholder="Enter your first name" ngModel required>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="lastName">Last Name *</label>
                        <input type="text" class="form-control" name="lastName" placeholder="Enter your last name" ngModel required>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="name">Phone *</label>
                        <input type="tel" class="form-control" name="phone" placeholder="Enter your phone" ngModel>
                    </div>
                    <div class="col-6 mb-3">
                        <label for="email">Email *</label>
                        <input type="email" class="form-control" name="email" placeholder="Enter your email address" ngModel required>
                    </div>
                    <div class="col-md-10" style="padding-top: 30px;">
                        <h3 class="text-white">On which of the Social Media platfroms are you active?</h3><br>
                    </div>
                    <div class="col-3 mb-3">
                        <input type="checkbox" name="facebook" value="Facebook" ngModel>
                        <label for="six"> Facebook</label>
                    </div>
                    <div class="col-3 mb-3">
                        <input type="checkbox" name="instagram" value="Instagram" ngModel>
                        <label for="seven"> Instagram</label>
                    </div>
                    <div class="col-3 mb-3">
                        <input type="checkbox" name="pinterest" value="Pinterest" ngModel>
                        <label for="eight"> Pinterest</label>
                    </div>
                    <div class="col-3 mb-3">
                        <input type="checkbox" name="tiktok" value="TikTok" ngModel>
                        <label for="nine"> TikTok</label>
                    </div>
                    <div class="col-3 mb-3">
                        <input type="checkbox" name="youtube" value="YouTube" ngModel>
                        <label for="eight"> YouTube</label>
                    </div>
                    <div class="col-3 mb-3">
                        <input type="checkbox" name="blogging" value="Blogging" ngModel>
                        <label for="nine"> Blogging</label>
                    </div>
                    <div class="col-3 mb-3">
                        <input type="checkbox" name="other" value="Other" ngModel>
                        <label for="nine"> Other</label>
                    </div>
                    <div class="col-3 mb-3" style="margin-top: -14px;">
                        <input type="text" name="othertext" class="form-control" placeholder="Please state which other" ngModel />
                    </div>
                    <div class="col-md-12" style="padding-top: 30px;">
                        <h3 class="text-white">Please provide a brief message why you want to become a TFM affiliate member.
                        </h3><br>
                    </div>
                    <div class="col-12 mb-3">
                        <label>Write Your Message *</label>
                        <textarea class="form-control" placeholder="Write your message" name="message" rows="5" ngModel required></textarea>
                    </div>
                </div>

                <div class="col-md-12 text-center">
                    <input type="submit" class="btn btn-default primary-btn transparent" style="position: relative;" value="Submit"/>
                    <span class="text-warning mt-2" *ngIf="formStatus.error"><br/><br/>{{formStatus.message}}</span>
                    <span class="text-success mt-2" *ngIf="formStatus.success"><br/><br/>{{formStatus.message}}</span>
                </div>
            </form>
        </div>
    </div>
</section>
<!--contact css end-->

<app-modern-sass-brand></app-modern-sass-brand>
<app-modern-sass-footer></app-modern-sass-footer>