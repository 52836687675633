<!--service css start-->
<section class="saas2 services">
    <div class="container saas2-services">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Our Features</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">Travel Find Me was created to bridge the gap between travelers 
                            and agencies by providing real solutions, with the latest technology in mind.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of services">
                <div class="service text-center">
                    <div class="img-block">
                        <img [src]="data.img" alt="" class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">{{data.title}}</h4>
                        <p>{{data.description}}</p>
  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <!--service css end-->
  