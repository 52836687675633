import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { TFMService } from '../../../../shared/service/tfm.service';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PremiumComponent implements OnInit {

  public formStatus : any = {
    message:"",
    errors:false,
    success:false
  };

  constructor(private tfmService:TFMService, private route: ActivatedRoute,
    private title: Title) { }

  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }

  onClickSubmit(data:NgForm) {
    this.formStatus.message = "";
    this.formStatus.success = false;
    this.formStatus.error = false;

    if (data.valid){

      var emailMessage = "";

      emailMessage += "<table>";
      emailMessage += "<tr><td>First Name:</td><td>" + data.value.firstName + "</td></tr>";
      emailMessage += "<tr><td>Last Name:</td><td>" + data.value.lastName + "</td></tr>";
      emailMessage += "<tr><td>Phone:</td><td>" + data.value.phone + "</td></tr>";
      emailMessage += "<tr><td>Email:</td><td>" + data.value.email + "</td></tr>";
      
      emailMessage += "<tr><td colspan='2'><b>Platforms</b></td></tr>";
      emailMessage += "<tr><td>Facebook:</td><td>" + data.value.facebook + "</td></tr>";
      emailMessage += "<tr><td>Instagram:</td><td>" + data.value.instagram + "</td></tr>";
      emailMessage += "<tr><td>Pinterest:</td><td>" + data.value.pinterest + "</td></tr>";
      emailMessage += "<tr><td>TikTok:</td><td>" + data.value.tiktok + "</td></tr>";
      emailMessage += "<tr><td>YouTube:</td><td>" + data.value.youtube + "</td></tr>";
      emailMessage += "<tr><td>Blogging:</td><td>" + data.value.blogging + "</td></tr>";
      emailMessage += "<tr><td>Other:</td><td>" + data.value.other + " | " + data.value.othertext + "</td></tr>";

      emailMessage += "<tr><td colspan='2'>&nbsp;</td></tr>";
      emailMessage += "<tr><td>Message:</td><td>" + data.value.message + "</td></tr>";
      emailMessage += "<table>";
    
      this.tfmService.sendEmail(data.value.name, "info@travelfind.me", "Affiliate Request", emailMessage).subscribe(res => {
        this.formStatus.message = "Message sent, we will get back to you soon!";
        this.formStatus.success = true;
      });
    }
    else {
      this.formStatus.message = "Please complete all fields";
      this.formStatus.error = true;
    }
  }

  features = [
    {
      img:'assets/images/saas2/advance-feature/13.png',
      title: 'Business & Domain Name',
      description: 'Make sure you have a name for your business including the registered domain name. If you do not have an domain we can assist if needed'
    },
    {
      img:'assets/images/saas2/advance-feature/14.png',
      title: 'Corporate Identity',
      description: 'Having a logo and corporate colours can help speed up website and marketing material. If you do not have your corporate identity yet, we can offer help with this as an ad-on service.'
    },
    {
      img:'assets/images/saas2/advance-feature/15.png',
      title: 'Content',
      description: 'We recommend having some form of content for at least these pages - about, contact, privacy policy and terms & conditions page.'
    },
    {
      img:'assets/images/saas2/advance-feature/16.png',
      title: 'Social Media',
      description: 'Social Media accounts are not neccassarily needed, but we would highly recommend having at least 1 or 2 for marketing.'
    }
  ]

  business = [
    {
      title:'OUR MISSION',
      description:'Our mission is to simplify travel, for the agent, agency and traveler. Keeping the agent and traveler connected through the whole booking experience.'
    },
    {
      title:'OUR VISION',
      description:'Our vision is to allow travelers to each have their own dedicated travel agent in their pocket by combining the latest technology with mobile devices.'
    }
  ]
}
