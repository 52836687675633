<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Turn Key Solutions for Agents & Agencies</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">Travel Find Me strives to help you go from travel business 
                            idea to having an up and running travel business in the most efficient time and 
                            cost as possible.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-md-7">
                <div class="center-text justify-content-center">
                    <div class="image-container">
                        <img src="assets/images/saas2/advance-feature/02.png" alt=""
                             class="img-fluid advance-feature-img">
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-5">
                <div *ngFor="let data of features">
                    <div class="center-content justify-content-start">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="feature-content">
                            <h5 class="feature-head">{{data.title}}</h5>
                            <p class="feature-para">{{data.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <!--feature css end-->
  