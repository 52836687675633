<app-modern-sass-nav></app-modern-sass-nav>
<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="center-text">
                            <div class="">
                                <div class="header-text">
                                    <h1>About Us</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="saas2-sub-text">The <span class="bold-text">Travel Find Me </span> Story
                                    </h3>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white">We are a company that started as a side
                                         business because we want to advance the travel industry when it comes 
                                         to the use of technology. We have since become a full time operation 
                                         working to "do away" with the separation between traveler and agent by 
                                         ensuring the tools exist for a smoother booking and travel experience.
                                    </p>
                                </div>
                                <a class="btn btn-default primary-btn transparent">Read the whole story</a>
                            </div>
                        </div>
                    </div>
                    <div class="center-text set-abs">
                        <img alt="" src="assets/images/saas2/aboutheader.png" class="img-fluid img-dextop">
                    </div>
                </div>
            </div>
  
            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->
  
<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Our Values</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">We hope that you experience what we built as an accurate and 
                            honest travel solution that attempts to bridge the gap between agency, agent and 
                            traveler in the booking process by cultivating a trustworthy and kind relationship 
                            to ensure repeat business for the agent and excellent service for the traveler.  
                            All of this is build on the latest security to protect agencies, agents and travelers.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-md-6">
                <div class="center-text justify-content-center">
                    <div class="image-container">
                        <img src="assets/images/saas2/advance-feature/03.png" alt=""
                             class="img-fluid advance-feature-img">
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-md-6">
                <div *ngFor="let data of features">
                    <div class="center-content justify-content-start">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="feature-content">
                            <h5 class="feature-head">{{data.title}}</h5>
                            <p class="feature-para">{{data.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature css end-->

<!--business css start-->
<section class="saas2 bg-gradient quick-sol" [ngStyle]="{'background-image': 'url(assets/images/saas2/background/quick-sol.png)'}">
    <div class="container set-center-div">
        <div class="row">
            <div class="col-lg-6">
                <div class="offers-container">
                    <h3 class="text-white offer-heading-text">Mission & Vision</h3>
                    <div class="bottom-border">
                    </div>
                    <div class="offers-content" *ngFor="let data of business">
                        <div class="d-flex">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                            <div>
                                <h4 class="offer-main-text text-white">{{data.title}}</h4>
                                <p class="offer-sub-text text-white">{{data.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="center-text side-img">
                <img alt="" src="assets/images/saas2/we-offer/aboutimg.png" class="img-fluid" data-tilt data-tilt-max="3"
                     data-tilt-speed="400" data-tilt-perspective="500">
            </div>
        </div>
    </div>
  </section>
  <!--business css end-->
  

<!-- <app-modern-sass-screenshots></app-modern-sass-screenshots> -->
<!-- <app-modern-sass-services></app-modern-sass-services> -->
<app-modern-sass-brand></app-modern-sass-brand>
<!-- <app-modern-sass-testimonial></app-modern-sass-testimonial> -->
<!-- <app-modern-sass-pricing></app-modern-sass-pricing> -->
<app-modern-sass-footer></app-modern-sass-footer>