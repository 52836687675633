import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Configuration } from '../../app.configuration';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

//import 'rxjs';
// import { AuthHttp } from 'angular2-jwt';
import {DomSanitizer} from '@angular/platform-browser';

import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Observable, ReplaySubject, throwError, of, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TFMService {
  private responseVersion: any;
  private responseAgencies: any;
  private responseSites: any;
  private responseContents: any;
  private responsePackages: any;

  public itinIdentifier : string = "";

  public itinGuid$: EventEmitter<string>;

  public localAgencySite$: EventEmitter<any>;

  constructor(private http: HttpClient, private _config: Configuration, private sanitizer: DomSanitizer) {
    this.itinGuid$ = new EventEmitter<string>();
    this.localAgencySite$ = new EventEmitter<any>();

    this.itinIdentifier = this._config._itinIdentifier;
  }

  options = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8', 
        'Accept': 'application/json',
        'Cache-Control':'not-store, no-cache',
        'Pragma':'no-cache',
        'Expires': '0',
        'Authorization':'Bearer ' + localStorage.getItem('id_token')
    })
  }  
  
  private tfmApiVersion : number = 1;

  public getCookie(name: string) {
      let ca: Array<string> = document.cookie.split(';');
      let caLen: number = ca.length;
      let cookieName = `${name}=`;
      let c: string;

      for (let i: number = 0; i < caLen; i += 1) {
          c = ca[i].replace(/^\s+/g, '');
          if (c.indexOf(cookieName) == 0) {
              return c.substring(cookieName.length, c.length);
          }
      }
      return '';
  }

  sendEmail(fromName, to, subject, message) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    let link = {
      rel:"add",
      method:"post",
      href:"https://api.travelfind.me/v1/email"
    }
    var content = {
      isHtmlBody:true,
      senderName:fromName,
      emailProviderID:"a432ef6c-7479-47f9-952a-b48716ab5b20",
      subject:subject,
      recipients: [
          {
              displayName:"TravelFind.Me",
              emailAddress:"info@travelfind.me"
          }
      ],
      emailBody:message
    }

    this.send(link, content).subscribe(res => {
      rs.next(res);
    });

    return rs;
  }

  itinGuid(itinGuid){
    this.itinGuid$.emit(itinGuid);
  }

  init() {
  }

  setLocalItinGuid(itinGuid) {
    localStorage.setItem(this.itinIdentifier, itinGuid);
    this.itinGuid(itinGuid);
  }
  getLocalItinGuid() {
    if (localStorage.getItem(this.itinIdentifier)) {
      return localStorage.getItem(this.itinIdentifier);
    }

    return null;
  }
  setLocalAgencySite(agencyID, siteID) {
    localStorage.setItem("agencyID", agencyID);
    localStorage.setItem("siteID", siteID);

    //Let other views know we have changed....
    this.localAgencySite$.emit(this.getLocalAgencySite());

  }
  getLocalAgencySite() {
    var agencyID = localStorage.getItem("agencyID") ? Number(localStorage.getItem("agencyID")) : 0;
    var siteID = localStorage.getItem("siteID") ? Number(localStorage.getItem("siteID")) : 0;

    return {
      agencyID:agencyID,
      siteID:siteID
    }
  }

  getTrips(agencyID, siteID) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    this.getSites(agencyID).subscribe(res=>{
      console.log("siteid",siteID);
      let site = res.content.filter(site => site.id == siteID)[0];

      let link = this.getLink(site._links, "itineraries");
      this.send(link).subscribe(res => {
        itinRS.next(res);
      });
    });

    return itinRS;
  }
  getContents(agencyID, siteID) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "contents");
      link.href = link.href.replace("{agencyId}", agencyID);
      link.href = link.href.replace("{siteId}", siteID);

      this.send(link).subscribe(res => {
        rs.next(res);
      });
    });

    return rs;
  }
  getContent(contentGuid, agencyID, siteID) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "contents");
      link.href = link.href.replace("{agencyId}", agencyID ? agencyID : this._config.TFMApiAgencyID);
      link.href = link.href.replace("{siteId}", siteID ? siteID : this._config.TFMApiSiteID);

      this.send(link).subscribe(res => {
        let content = res.content.filter(x=>x.guid == contentGuid)[0];

        rs.next(content);
      });
    });

    return rs;
  }

  getProfiles(agencyID, siteID) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "profiles");
      link.href = link.href.replace("{agencyId}", agencyID);
      link.href = link.href.replace("{siteId}", siteID);

      this.send(link).subscribe(res => {
        rs.next(res);
      });
    });

    return rs;
  }
  getSuppliers(agencyID, siteID, supplierGuid = null) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "sites");
      link.href = link.href.replace("{agencyId}", agencyID);

      this.send(link).subscribe(res => {
        var site = res.content.filter(x=>x.id == siteID)[0];
        if (site) {
          link = this.getLink(site._links, "suppliers");
          
          this.send(link).subscribe(res => {
            if (supplierGuid) {
              let supplier = res.content.filter(x=>x.guid == supplierGuid);
              if (supplier) {
                rs.next(supplier[0]);
              }
              else {
                rs.next(null);
              }
            }
            else {
              rs.next(res);
            }
          });
        }
      });
    });

    return rs;
  }
  

  getExtendedDataImage(provider, id) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "extended-data-images");
      link.href = link.href.replace("{provider}", provider);
      link.href = link.href.replace("{id}", id);

      this.send(link).subscribe(res => {
        rs.next(res);
      });
    });

    return rs;
  }
  addSubscriber(content:any) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "sites");
      link.href = link.href.replace("{agencyId}", this._config.TFMApiAgencyID);

      this.send(link).subscribe(res => {
        
        let link2 = this.getLink(res.content.filter(x=>x.id == this._config.TFMApiSiteID)[0]._links, "subscribers");
        this.send(link2).subscribe(res => {
          
          let link3 = this.getLink(res._links, "add");
          this.send(link3, content).subscribe(res => {
            
            rs.next(res);
          });
        });
      });
    });

    return rs;
  }
  addProfile(content:any, agencyID, siteID) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "profiles");
      link.href = link.href.replace("{agencyId}", agencyID ? agencyID : this._config.TFMApiAgencyID);
      link.href = link.href.replace("{siteId}", siteID ? siteID : this._config.TFMApiSiteID);

      this.send(link).subscribe(res => {
        
        let link2 = this.getLink(res._links, "add");

        this.send(link2, content).subscribe(res => {
          
          rs.next(res);
        });
      });
    });

    return rs;
  }
  updateProfile(content:any, agencyID, siteID) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "profiles");
      link.href = link.href.replace("{agencyId}", agencyID ? agencyID : this._config.TFMApiAgencyID);
      link.href = link.href.replace("{siteId}", siteID ? siteID : this._config.TFMApiSiteID);

      this.send(link).subscribe(res => {
        let profile = res.content.filter(x=>x.guid == content.guid)[0];
        let link2 = this.getLink(profile._links, "edit");

        this.send(link2, content).subscribe(res => {
          
          rs.next(res);
        });
      });
    });

    return rs;
  }
  login(content:any) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "user-login");
      link.href = link.href.replace("{agencyId}", this._config.TFMApiAgencyID);
      link.href = link.href.replace("{siteId}", this._config.TFMApiSiteID);

      this.send(link, content).subscribe(res => {
        rs.next(res);
      });
    });

    return rs;
  }

  


  forgotpassword(content:any) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "sites") ;
      link.href = link.href.replace("{agencyId}", this._config.TFMApiAgencyID);
  
      this.send(link, content).subscribe(res => {
        let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];
        let link = this.getLink(site._links, "profile-forgot-password");
      this.send(link, content).subscribe(res => {
          rs.next(res);
      });
      });
    });

    return rs;
  }

    resetpassword(content:any) {
var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "sites") ;
      link.href = link.href.replace("{agencyId}", this._config.TFMApiAgencyID);
  
      this.send(link, content).subscribe(res => {
        let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];
        let link = this.getLink(site._links, "profile-reset-password");
 
      this.send(link, content).subscribe(res => {
          rs.next(res);
      });
      });
    });

    return rs;
  }


  getProfile(profileGuid, agencyID, siteID) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "profiles");
      link.href = link.href.replace("{agencyId}", agencyID ? agencyID : this._config.TFMApiAgencyID);
      link.href = link.href.replace("{siteId}", siteID ? siteID : this._config.TFMApiSiteID);

      this.send(link).subscribe(res => {
        let profile = res.content.filter(x=>x.guid == profileGuid)[0];

        rs.next(profile);
      });
    });

    return rs;
  }
  

  getAgencies() {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{

      // if (this.responseAgencies){
      //   console.log("Returned from local cache");
      //   rs.next(this.responseAgencies);
      // }
      // else 
      {
        console.log("Doing DB Call");
        let link = this.getLink(res._links, "agencies");

        this.send(link).subscribe(res => {
          this.responseAgencies = res;
          
          rs.next(this.responseAgencies);
        });
      }
    });

    return rs;
  }

  getSite() {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getSites().subscribe(res => {

      let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

      rs.next(site);
    });

    return rs;
  }
  getSites(agencyId?) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    var localAgencySite = this.getLocalAgencySite();

    this.getVersion().subscribe(res=>{
 
        console.log("Doing DB Call");
        let link = this.getLink(res._links, "sites");
        link.href = link.href.replace("{agencyId}", agencyId ? agencyId : localAgencySite.agencyID);

        this.send(link).subscribe(res => {
          this.responseSites = res;
          
          rs.next(this.responseSites);
        });
     
    });

    return rs;
  }

  getSuppliersAll() {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getSite().subscribe(res => {
      let link = this.getLink(res._links, "suppliers");

      this.send(link).subscribe(supplierRes => {

        supplierRes.content.forEach(supplier => {
          if (supplier.image) 
          {
            supplier.image.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.getLink(supplier.image._links, "view").href);
            supplier.image.imageUrlBg = this.sanitizer.bypassSecurityTrustStyle('url(' + this.getLink(supplier.image._links, "view").href + ')');
          }
          if (supplier.images && supplier.images.length > 0) 
          {
            supplier.images.forEach(img => {
              img.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.getLink(img._links, "view").href);
              img.imageUrlBg = this.sanitizer.bypassSecurityTrustStyle('url(' + this.getLink(img._links, "view").href + ')');
            });
          }
        });

        rs.next(supplierRes);
      });
    });

    return rs;
  }

  getLoggedInUserId(){
    let userId = 0;
    //If user logged in, get id
    if (localStorage.getItem("agentId") && !isNaN(Number(localStorage.getItem("agentId"))))
      userId = Number(localStorage.getItem("agentId"));

    return userId;
  }
  getLoggedInUser() {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "agents");
      link.href = link.href.replace("{agencyId}", 0);

      this.send(link).subscribe(res => {
        let user = res.content.filter(x=>x.id == this.getLoggedInUserId())[0];

        rs.next(user);
      });
    });

    return rs;
  }

  saveSearch(content : any, type) {
    var search:ReplaySubject<any> = new ReplaySubject(1);
    
    var localAgencySite = this.getLocalAgencySite();

    content.profileId = this.getLoggedInUserId();
    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "sites");
      link.href = link.href.replace("{agencyId}", localAgencySite.agencyID > 0 ? localAgencySite.agencyID : this._config.TFMApiAgencyID);
      
      this.send(link).subscribe(res => {
        let site = res.content.filter(site => site.id == (localAgencySite.siteID > 0 ? localAgencySite.siteID : this._config.TFMApiSiteID))[0];

        let link = this.getLink(site._links, "searches");
        this.send(link).subscribe(res => {
          
          let link = null;
          if (type == 0){
            link = this.getLink(res._links, "add-default");
          }
          else if (type == 5){
            link = this.getLink(res._links, "add-flight-hotel-golf-car");
          }
          else if (type == 6){
            link = this.getLink(res._links, "add-flight-hotel-golf");
          }
          else if (type == 7){
            link = this.getLink(res._links, "add-hotel-golf-car");
          }
          else if (type == 8){
            link = this.getLink(res._links, "add-accommodation");
          }
          else if (type == 9){
            link = this.getLink(res._links, "add-flight");
          }
          else if (type == 10){
            link = this.getLink(res._links, "add-carhire");
          }
          else if (type == 11){
            link = this.getLink(res._links, "add-transfer");
          }
          
          if (link) {
            
            this.send(link, content).subscribe(res => {
              
              search.next(res);
            });
          }
        });
      });
    });

    return search;
  }
  createItin(content : any) {
  
    var itin:ReplaySubject<any> = new ReplaySubject(1);
    content.userId = this.getLoggedInUserId();
    
    var localAgencySite = this.getLocalAgencySite();

    this.getSites().subscribe(res => {
      let site = res.content.filter(site => site.id == (localAgencySite.siteID))[0];
      let link = this.getLink(site._links, "itineraries");
      
      let linkAdd = {
        rel:"add",
        method:"post",
        href:this._config.TFMApiLink.href + "/v1/sites/"+(localAgencySite.siteID)+"/itineraries"
      }
      this.send(linkAdd, content).subscribe(res => {
        itin.next(res);
      });

      // this.send(link).subscribe(res => {
      //   let link = this.getLink(res._links, "add");
      //   console.log("Add", res._links);
      //   this.send(link, content).subscribe(res => {
      //     itin.next(res);
      //   });
      // });
    });

    return itin;
  }
  getItins(userId, agencyID, siteID) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    this.getSites().subscribe(res=>{
      let site = res.content.filter(site => site.id == (siteID ? siteID : this._config.TFMApiSiteID))[0];

      let link = this.getLink(site._links, "itineraries");
      this.send(link).subscribe(res => {
        
        let itin = res.content.filter(x=>x.itinerary.profileId == userId);          
        itinRS.next(itin);
      });
    });

    return itinRS;
  }
  getSearches(userId) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    this.getSites().subscribe(res=>{
      let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

      let link = this.getLink(site._links, "searches");
      this.send(link).subscribe(res => {
        
        let searches = res.content.filter(x=>x.profileId == userId);          
        rs.next(searches);
      });
    });

    return rs;
  }
  getItinFromId(itinId) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    this.getSites().subscribe(res=>{
      let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

      let link = this.getLink(site._links, "itineraries");
      this.send(link).subscribe(res => {
        
        let itin = res.content.filter(x=>x.itinerary.id == itinId)[0];          
        if (itin) {
          let link = this.getLink(itin.itinerary._links, "self");
          this.send(link).subscribe(res => {
            itinRS.next(res);
          });
        }
        else {
          itinRS.next(null);
        }
      });
    });

    return itinRS;
  }
  getItin(itinGuid) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    if (this._config._debug)
      console.log("getItin id", itinGuid);

    var localAgencySite = this.getLocalAgencySite();

    this.getSites().subscribe(res=>{
      let site = res.content.filter(site => site.id == localAgencySite.siteID)[0];

      if (this._config._debug)
        console.log("getItin site response", site);

      let link = this.getLink(site._links, "itinerary");
      link.href = link.href.replace("{guid}", itinGuid);

      if (this._config._debug)
        console.log("getItin link", link);

      this.send(link).subscribe(res => {
        let itin = res.content;    
        
        if (this._config._debug)
          console.log("getItin itin", itin);

        let link = this.getLink(itin.itinerary._links, "self");
        this.send(link).subscribe(res => {
          itinRS.next(res);
        });
      });
    });

    return itinRS;
  }
  updateItin(itinGuid, content : any) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    content.profileId = this.getLoggedInUserId();

    var localAgencySite = this.getLocalAgencySite();

    this.getSites().subscribe(res=>{
      
    let site = res.content.filter(site => site.id == localAgencySite.siteID)[0];

    let linkGetItin = {
      rel:"self",
      method:"get",
      href:this._config.TFMApiLink.href + "v1/sites/" + localAgencySite.siteID + "/itineraries/" + itinGuid
    }
    this.send(linkGetItin).subscribe(res => {
      let itin = res.content;

      let link = this.getLink(itin.itinerary._links, "edit");
      this.send(link, content).subscribe(res => {
        itinRS.next(res);
      });
    });

    //let link = this.getLink(site._links, "itineraries");
    // this.send(link).subscribe(res => {
    //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];
      
    //   let link = this.getLink(itin.itinerary._links, "edit");
    //   this.send(link, content).subscribe(res => {
    //     itinRS.next(res);
    //   });
    // });
    });

    return itinRS;
  }
  getItinItems(itinGuid) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    var localAgencySite = this.getLocalAgencySite();

    this.getSites().subscribe(res=>{
      let site = res.content.filter(site => site.id == localAgencySite.siteID)[0];

      let linkGetItin = {
        rel:"self",
        method:"get",
        href:this._config.TFMApiLink.href + "v1/sites/" + localAgencySite.siteID + "/itineraries/" + itinGuid
      }
      this.send(linkGetItin).subscribe(res => {
        let itin = res.content;

        let link = this.getLink(itin.itinerary._links, "items");
        this.send(link).subscribe(res => {
          itinRS.next(res);
        });
      });

      // let link = this.getLink(site._links, "itineraries");
      // this.send(link).subscribe(res => {
      //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];
        
      //   let link = this.getLink(itin.itinerary._links, "items");
      //   this.send(link).subscribe(res => {
      //     itinRS.next(res);
      //   });
      // });
    });

    return itinRS;
  }
  addItinItem(itinGuid, content : any) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    content.profileId = this.getLoggedInUserId();

    var localAgencySite = this.getLocalAgencySite();

    this.getSites().subscribe(res=>{
        let site = res.content.filter(site => site.id == localAgencySite.siteID)[0];

        let linkGetItin = {
          rel:"self",
          method:"get",
          href:this._config.TFMApiLink.href + "v1/sites/" + localAgencySite.siteID + "/itineraries/" + itinGuid
        }
        this.send(linkGetItin).subscribe(res => {
          let itin = res.content;

          let link = this.getLink(itin.itinerary._links, "items");
          this.send(link).subscribe(res => {
            let link = this.getLink(res._links, "add");
            this.send(link, content).subscribe(res => {
              itinRS.next(res);
            });
          });
        });

        // let link = this.getLink(site._links, "itineraries");
        // this.send(link).subscribe(res => {
        //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];          
          
        //   let link = this.getLink(itin.itinerary._links, "items");
        //   this.send(link).subscribe(res => {
        //     let link = this.getLink(res._links, "add");
        //     this.send(link, content).subscribe(res => {
        //       itinRS.next(res);
        //     });
        //   });
        // });
    });

    return itinRS;
  }
  getItinPax(itinGuid) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    var localAgencySite = this.getLocalAgencySite();

    this.getSites().subscribe(res=>{
      let site = res.content.filter(site => site.id == localAgencySite.siteID)[0];

      let linkGetItin = {
        rel:"self",
        method:"get",
        href:this._config.TFMApiLink.href + "v1/sites/" + localAgencySite.siteID + "/itineraries/" + itinGuid
      }
      this.send(linkGetItin).subscribe(res => {
        let itin = res.content;
        let link = this.getLink(itin.itinerary._links, "persons");
        this.send(link).subscribe(res => {
          itinRS.next(res);
        });
      });
      
      // let link = this.getLink(site._links, "itineraries");
      // this.send(link).subscribe(res => {
      //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];
        
      //   let link = this.getLink(itin.itinerary._links, "persons");
      //   this.send(link).subscribe(res => {
      //     itinRS.next(res);
      //   });
      // });
    });

    return itinRS;
  }
  updatePax(pax) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    let link = this.getLink(pax._links, "edit");
    if (link) {
      this.send(link, pax).subscribe(res => {
        itinRS.next(res);
      });
    }
    else {
      itinRS.next(null);
    }

    return itinRS;
  }
  addItinPax(itinGuid, content : any) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    if (this._config._debug)
          console.log("service.addItinPax", content);

    this.getSites().subscribe(res=>{
        let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

        let linkGetItin = {
          rel:"self",
          method:"get",
          href:this._config.TFMApiLink.href + "v1/sites/18/itineraries/" + itinGuid
        }
        this.send(linkGetItin).subscribe(res => {
          let itin = res.content;

          let link = this.getLink(itin.itinerary._links, "persons");
          this.send(link).subscribe(res => {
            let link = this.getLink(res._links, "add");
            this.send(link, content).subscribe(res => {
              itinRS.next(res);
            });
          });
        });

        // let link = this.getLink(site._links, "itineraries");
        // this.send(link).subscribe(res => {
        //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];          
          
        //   let link = this.getLink(itin.itinerary._links, "persons");
        //   this.send(link).subscribe(res => {
        //     let link = this.getLink(res._links, "add");
        //     this.send(link, content).subscribe(res => {
        //       itinRS.next(res);
        //     });
        //   });
        // });
    });

    return itinRS;
  }
  createMessage(content : any) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    if (localStorage.getItem(this.itinIdentifier)) {
      let itinGuid = localStorage.getItem(this.itinIdentifier);

      this.getSites().subscribe(res=>{
        let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

        let linkGetItin = {
          rel:"self",
          method:"get",
          href:this._config.TFMApiLink.href + "v1/sites/18/itineraries/" + itinGuid
        }
        this.send(linkGetItin).subscribe(res => {
          let itin = res.content;

          if (itin) {
            let link = this.getLink(itin.itinerary._links, "messages");
            this.send(link).subscribe(res => {
              let link = this.getLink(res._links, "add");
              this.send(link, content).subscribe(res => {
                rs.next(res);
              });
            });
          }
        });
        
        // let link = this.getLink(site._links, "itineraries");
        // this.send(link).subscribe(res => {
        //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];
        //   if (itin) {
        //     let link = this.getLink(itin.itinerary._links, "messages");
        //     this.send(link).subscribe(res => {
        //       let link = this.getLink(res._links, "add");
        //       this.send(link, content).subscribe(res => {
        //         rs.next(res);
        //       });
        //     });
        //   }
        // });
      });
    }
    else {
      rs.next(null);
    }

    return rs;
  }
  submitHubspotForm(formGuid, content) {
    var rs:ReplaySubject<any> = new ReplaySubject(1);

    let finalContent = {
      content:content,
      hsContext:{
        'hutk':this.getCookie("hubspotutk"),
        'ipAddress':'',
        'pageUrl':document.location.href,
        'pageName':document.title
      }
    }

    this.getSites().subscribe(res=>{
        let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

        let link = this.getLink(site._links, "tools");
        this.send(link).subscribe(res => {
          link = this.getLink(res._links, "hubspot");
          this.send(link).subscribe(res => {
            link = this.getLink(res._links, "form-submit");
            //Replace formGuid in url.
            link.href = link.href.replace("{formGuid}", formGuid);
            this.send(link, finalContent).subscribe(res => {
              rs.next(res);
            });
          });
        });
    });

    return rs;
  }
  prebookItin(itinGuid) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    this.getSites().subscribe(res=>{
        let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

        let linkGetItin = {
          rel:"self",
          method:"get",
          href: this._config.TFMApiLink.href + "v1/sites/18/itineraries/" + itinGuid
        }
        this.send(linkGetItin).subscribe(res => {
          let itin = res.content;

          let link = this.getLink(itin.itinerary._links, "prebook");
          this.send(link).subscribe(res => {
            itinRS.next(res);
          });
        });

        // let link = this.getLink(site._links, "itineraries");
        // this.send(link).subscribe(res => {
        //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];          
          
        //   let link = this.getLink(itin.itinerary._links, "prebook");
        //   this.send(link).subscribe(res => {
        //     itinRS.next(res);
        //   });
        // });
    });

    return itinRS;
  }
  bookItin(itinGuid) {
    var itinRS:ReplaySubject<any> = new ReplaySubject(1);

    this.getSites().subscribe(res=>{
        let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

        let linkGetItin = {
          rel:"self",
          method:"get",
          href:this._config.TFMApiLink.href + "v1/sites/18/itineraries/" + itinGuid
        }
        this.send(linkGetItin).subscribe(res => {
          let itin = res.content;

          let link = this.getLink(itin.itinerary._links, "book");
          this.send(link).subscribe(res => {
            itinRS.next(res);
          });
        });
        // let link = this.getLink(site._links, "itineraries");
        // this.send(link).subscribe(res => {
        //   let itin = res.content.filter(x=>x.itinerary.guid == itinGuid)[0];          
          
        //   let link = this.getLink(itin.itinerary._links, "book");
        //   this.send(link).subscribe(res => {
        //     itinRS.next(res);
        //   });
        // });
    });

    return itinRS;
  }
  getLocations(placeName : string) {
    var locations:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      if (!placeName) {
        locations.next(null);
      }
      else {
        let link = this.getLink(res._links, "locations");
        link.href = link.href.replace("{placeName}", placeName);

        this.send(link).subscribe(res => {
          locations.next(res.content.predictions);
        });
      }
    });
    
    return locations;
  }
  getPlaceDetail(placeId : string) {
    var placeDetail:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      if (!placeId) {
        placeDetail.next(null);
      }
      else {
        let link = { method:"get", href:"http://api.travelfind.me/v1/locations/{placeId}/details" };//this.getLink(res._links, "place_detail");
        link.href = link.href.replace("{placeId}", placeId);

        this.send(link).subscribe(res => {
          placeDetail.next(res);
        });
      }
    });
    
    return placeDetail;
  }
  getAirports(placeName : string) {
    var locations:ReplaySubject<any> = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      if (!placeName) {
        locations.next(null);
      }
      else {
        let link = this.getLink(res._links, "airports");
        link.href = link.href.replace("{placeName}", placeName);

        this.send(link).subscribe(res => {
          locations.next(res.content.results);
        });
      }
    });

    return locations;
  }
  private responseContentsRS : ReplaySubject<any>;
  private responseContentsRSInit : boolean = false;
  getContentsAll() {
    
    if (!this.responseContentsRS)
      this.responseContentsRS = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "contents");
      link.href = link.href.replace("{agencyId}", this._config.TFMApiAgencyID);
      link.href = link.href.replace("{siteId}", this._config.TFMApiSiteID);

      if (this.responseContents){
        this.responseContentsRS.next(this.responseContents);
        return this.responseContentsRS;
      }
      else if (this.responseContentsRS && this.responseContentsRSInit){
        return this.responseContentsRS;
      }
      else {
        this.responseContentsRSInit = true;

        this.send(link).subscribe(res => {
          this.responseContents = res;
          this.responseContentsRS.next(this.responseContents);

          this.responseContentsRS = null;
          this.responseContentsRSInit = false;
        });
      }
      return this.responseContentsRS;
    });

    return this.responseContentsRS;
  }
  filterContents(res, type: string, alias: string = null, tag: string = null, guid: string = null){
    let typeId = 0;
    if (type == 'article')
      typeId = 1;
    if (type == 'destination')
      typeId = 2;
    if (type == 'testimonial')
      typeId = 4;
    if (type == 'page')
      typeId = 5;

    let contents : any;

    contents = res.content.filter(content => content.isActive);
    if (tag != null) {
      contents = res.content.filter(content => content.tags != null && content.tags.toLowerCase().replace(new RegExp(' ', 'g'), "-").includes(tag.toLowerCase().replace(new RegExp(' ', 'g'), "-")));
    }

    if (type != null) {
      contents = contents.filter(content => content.contentTypeID == typeId);          
    }
    else {
      contents = contents.filter(content => content.siteID == this._config.TFMApiSiteID);
    }

    for (var x = 0; x < contents.length; x++){
      contents[x].rawContentShortSafe = this.sanitizer.bypassSecurityTrustHtml(contents[x].rawContentShort);
      contents[x].rawContentSafe = this.sanitizer.bypassSecurityTrustHtml(contents[x].rawContent);

      if (contents[x].images && contents[x].images.length > 0){
        for (var i = 0; i < contents[x].images.length; i++){
          contents[x].images[i].imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.getLink(contents[x].images[i]._links, "view").href);
          contents[x].images[i].imageUrlBg = this.sanitizer.bypassSecurityTrustStyle('url(' + this.getLink(contents[x].images[i]._links, "view").href + ')');
        }

        var featuredImg = contents[x].images.filter(img => img.isFeatured == true);
        if (featuredImg.length > 0) {
          contents[x].imageUrl = featuredImg[0].imageUrl;
          contents[x].imageUrlBg = featuredImg[0].imageUrlBg;
        }
        else {
          contents[x].imageUrl = contents[x].images[0].imageUrl;
          contents[x].imageUrlBg = contents[x].images[0].imageUrlBg;
        }
      }
    }

    //Get the pinned and sortindexed items
    var fixedContent = contents.filter(x=>x.isPinned || x.sortIndex > 0);
    var variableContent = contents.filter(x=>!x.isPinned && !x.sortIndex);
    if (type == 'destination')
      variableContent = this.shuffle(variableContent);

    variableContent.forEach(element => {
      fixedContent.push(element);
    });

    contents = fixedContent;

    if (alias){
      contents = contents.filter(content => content.seo.alias == alias)[0];
    }
    
    if (guid){
      contents = contents.filter(content => content.guid == guid)[0];
    }

    return contents;
  }
  getSearch(guid: string, cbResults: (data) => void) {
    this.getSites().subscribe(res => {
      let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

      let link = this.getLink(site._links, "searches");
      this.send(link).subscribe(res => {
        var search = res.content.filter(search => search.guid == guid)[0];

        cbResults(search);
      });
    });
  }
  getSearchResults(guid: string, searchType: string, cbQuery: (data) => void, cbResults: (data) => void) {
    this.getSites().subscribe(res => {
      let site = res.content.filter(site => site.id == this._config.TFMApiSiteID)[0];

      let link = this.getLink(site._links, "searches");
      this.send(link).subscribe(res => {
        
        let linkQuery = this.getLink(res.content.filter(search => search.guid == guid)[0]._links, "query-" + searchType);
        let linkResults = this.getLink(res.content.filter(search => search.guid == guid)[0]._links, "search-results-" + searchType);
        
        this.send(linkQuery).subscribe(res => {
          cbQuery(res);          
          this.send(linkResults).subscribe(res => {
            cbResults(res);
          });
        });
      });
    });
  }

  getImagesHotel(roomStay, data) {
    if (data != null && roomStay != null) {
      //this._service.getExtendedDataImage(this.results.accommodation.data[0].provider, roomStay.basicPropertyInfo.ttIcode).subscribe(res => {
      this.getExtendedDataImage("tfm-supplier", roomStay.basicPropertyInfo.ttIcode).subscribe(res => {
        //Find the correct roomstay
        data.results.roomStays.roomStay.filter(stay => stay.basicPropertyInfo.ttIcode == res.id)[0].basicPropertyInfo.images = res.images;

        if (data.results.roomStays.roomStay.filter(stay => stay.basicPropertyInfo.ttIcode == res.id)[0].basicPropertyInfo.images.length > 0)
          data.results.roomStays.roomStay.filter(stay => stay.basicPropertyInfo.ttIcode == res.id)[0].basicPropertyInfo.image = data.results.roomStays.roomStay.filter(stay => stay.basicPropertyInfo.ttIcode == res.id)[0].basicPropertyInfo.images[0];
      });
    }
  }
  public getImagesCar(car, data) {
    if (data != null && car != null) {
      //this._service.getExtendedDataImage(this.results.accommodation.data[0].provider, roomStay.basicPropertyInfo.ttIcode).subscribe(res => {
      this.getExtendedDataImage("tfm-service", car.vehAvailCore.vendor.code).subscribe(res => {
        //Find the correct roomstay
        data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.images = res.images;

        if (data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.images.length > 0)
          data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.image = data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.images[0];
          data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.pictureURL = data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.image;
      });
    }
  }
  public getImagesTransfer(transfer, data) {
    if (data != null && transfer != null) {
      //this._service.getExtendedDataImage(this.results.accommodation.data[0].provider, roomStay.basicPropertyInfo.ttIcode).subscribe(res => {
      this.getExtendedDataImage("tfm-service", transfer.vehAvailCore.vendor.code).subscribe(res => {

        //Find the correct roomstay
        data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.images = res.images;

        if (data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.images.length > 0)
          data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.image = data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.images[0];
          data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.pictureURL = data.results.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvail.filter(c => c.vehAvailCore.vendor.code == res.id)[0].vehAvailCore.vehicle.image;
      });
    }
  }

  //private responsePackagesRS : ReplaySubject<any>;
  private responsePackagesRSInit : boolean = false;
  getPackages(query = "", promo = false, search = "", alias = "") {
    // if (!this.responsePackagesRS)
    //   this.responsePackagesRS = new ReplaySubject(1);
    var rs = new ReplaySubject(1);

    this.getVersion().subscribe(res=>{
      let link = this.getLink(res._links, "services-by-type");
      link.href = link.href.replace("{agencyId}", this._config.TFMApiAgencyID);
      link.href = link.href.replace("{siteId}", this._config.TFMApiSiteID);
      link.href = link.href.replace("{serviceType}", "package");

      if (query == null)
        query = " ";
      if (search == null)
        search = " ";
      if (alias == null)
        alias = " ";
      link.href += "/" + query + "/" + promo + "/" + search + "/" + alias;

      // if (this.responsePackages){
      //   this.responsePackagesRS.next(this.responsePackages);
      //   return this.responsePackagesRS;
      // }
      // else if (this.responsePackagesRS && this.responsePackagesRSInit){
      //   return this.responsePackagesRS;
      // }
      // else 
      {
        this.responsePackagesRSInit = true;

        this.send(link).subscribe(res => {
          this.responsePackages = res;
          rs.next(this.responsePackages);

          // this.responsePackagesRS = null;
          // this.responsePackagesRSInit = false;
        });
      }
    });

    return rs;
  }
  filterPackages(res, tag: string, onPromo:boolean = false, alias: string = null, onBanner:boolean = false, searchString: string = null){
    let packages : any;
    
    packages = res.content.filter(pkg => pkg.isActive);
    if (tag != null) {
      packages = packages.filter(pkg => pkg.tags != null && pkg.tags.trim().toLowerCase().replace(new RegExp(' ', 'g'), "-").includes(tag.trim().toLowerCase().replace(new RegExp(' ', 'g'), "-")));
    }

    if (onPromo) {
      packages = packages.filter(pkg => pkg.onPromo == true);
    }
    if (onBanner) {
      packages = packages.filter(pkg => pkg.onBanner == true);
    }

    if (searchString) {
      searchString = searchString.trim().replace(/^\s+|\s+$/g,'');

      var str_array = searchString.split(',');

      var tempPackages = [];
      for(var i = 0; i < str_array.length; i++) {
        packages.filter(pkg => 
          (pkg.tags != null && pkg.tags.trim().toLowerCase().includes(str_array[i].toLowerCase())) || 
          (pkg.name != null && pkg.name.toLowerCase().includes(str_array[i].toLowerCase())) || 
          (pkg.tagline != null && pkg.tagline.toLowerCase().includes(str_array[i].toLowerCase())) || 
          (pkg.contentShort != null && pkg.contentShort.toLowerCase().includes(str_array[i].toLowerCase())) || 
          (pkg.place != null && pkg.place.toLowerCase().includes(str_array[i].toLowerCase()))
        ).forEach(e => {
          tempPackages.push(e);
        });
      }

      packages = tempPackages;
    }    

    for (var x = 0; x < packages.length; x++){
      packages[x].contentSafe = this.sanitizer.bypassSecurityTrustHtml(packages[x].content);
      packages[x].contentExcludeSafe = this.sanitizer.bypassSecurityTrustHtml(packages[x].contentExclude);
      packages[x].contentIncludeSafe = this.sanitizer.bypassSecurityTrustHtml(packages[x].contentInclude);
      packages[x].contentShortSafe = this.sanitizer.bypassSecurityTrustHtml(packages[x].contentShort);
      packages[x].contentHighlightSafe = this.sanitizer.bypassSecurityTrustHtml(packages[x].contentHighlight);

      if (packages[x].images && packages[x].images.length > 0){
        for (var i = 0; i < packages[x].images.length; i++){
          packages[x].images[i].imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.getLink(packages[x].images[i]._links, "view").href);
          packages[x].images[i].imageUrlBg = this.sanitizer.bypassSecurityTrustStyle('url("' + this.getLink(packages[x].images[i]._links, "view").href + '")');
        }

        var featuredImg = packages[x].images.filter(img => img.isFeatured == true);
        if (featuredImg.length > 0) {
          packages[x].imageUrl = featuredImg[0].imageUrl;
          packages[x].imageUrlBg = featuredImg[0].imageUrlBg;
        }
        else {
          packages[x].imageUrl = packages[x].images[0].imageUrl;
          packages[x].imageUrlBg = packages[x].images[0].imageUrlBg;
        }
      }
      if (packages[x].documents && packages[x].documents.length > 0){
        for (var i = 0; i < packages[x].documents.length; i++){
          packages[x].documents[i].documentUrl = this.sanitizer.bypassSecurityTrustUrl(this.getLink(packages[x].documents[i]._links, "view").href);
        }
      }
    }

    //packages.reverse();

    if (alias){
      packages = packages.filter(pkg => pkg.seo.alias == alias)[0];
    }

    return packages;
  }
  shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
  private responseVersionRS : ReplaySubject<any>;
  getVersion() {
    if (this.responseVersion){
      //this.responseVersionRS = new ReplaySubject(1);
      //this.responseVersionRS.next(this.responseVersion);
      return of(this.responseVersion);
    }
    else if (this.responseVersionRS){
      return this.responseVersionRS;
    }
    else {
      this.responseVersionRS = new ReplaySubject(1);
      this.send(this._config.TFMApiEntryLink).subscribe(res => {
        this.responseVersion = res.versions.filter(x => x.value == this.tfmApiVersion)[0];
        this.responseVersionRS.next(this.responseVersion);

        this.responseVersionRS = null;
      });
    }

    return this.responseVersionRS;
  }
  getContentLink(link) {
    if (link.method == "get"){
      return this.http
        .get<any>(link.href, this.options)
        .pipe(map(res => res));
    }

    return EMPTY;
  }
  filterAndSend(links, rel, content:any = {}){
    let link = this.getLink(links, rel);

    return this.send(link, content);
  }
  postUrlString(url, content:any = {}) {
    return this.http.post(url, content, this.options);

  }
  secureObj(link){
    if (this._config._debug) 
        console.log("secureObj", link);

    link.href = this.secure(link.href);

    return link;
  }
  secure(link){
    if (this._config._debug) 
      console.log("secure", link);
    
      if (!this._config._local) {
      if (link.indexOf("ttp://api.travelfind.me")){
        link = link.replace("ttp://", "ttps://");
      }
    }

    return link;
  }
  send(link, content:any = {}): Observable<any>{
    this.secureObj(link);

    if (this._config._debug) 
    {
      console.log("Link requested", link, content);
      if (link.method != "get"){
        
      }
    }

    if (link.method == "get"){
      if (this._config._debug) 
        console.log("Link requested", link, content);

      return this.http
        .get<any>(link.href, this.options)
        .pipe(map(res => res));
    }
    else if (link.method == "post") {
      if (this._config._debug) 
        console.log("Link requested", link, content);

      return this.http.
        post<any>(link.href, content, this.options)
        .pipe(mergeMap(res => 
            {
            if (this._config._debug) {
              console.log(res);
            }

            if (res && res.headers && res.headers.get('Location')) {

              var location = res.headers.get('Location');

              if (this._config._debug) 
                console.log("Link returned and now sending", location);

              location = this.secure(location);
              return this.http
                .get<any>(location, this.options)
                .pipe(map(res => res));
            }
            else {

              return of(res);
            }
        }));
    }
    else if (link.method == "put") {
      if (this._config._debug) 
        console.log("Link requested", link, content);

      return this.http.
        put<any>(link.href, content, this.options)
        .pipe(mergeMap(res => {
          return of(res);
        }));
    }
    else if (link.method == "delete") {
      if (this._config._debug) 
        console.log("Link requested", link, content);

      return this.http.delete(link.href, this.options)
        .pipe(mergeMap(res => {
            if (this._config._debug) {
              
            }
            return of(new TFMResponse());
        }));
    }

    return of(new TFMResponse());
  }

  getLink(links, rel) {
    let linkCollection = links.filter(x => x.rel == "curies" ? x.name == rel : x.rel == rel);
    if (linkCollection && linkCollection.length > 0) {
      let link = Object.create(linkCollection[0]);
      return link;
    }

    return null;
  }
}
export class TFMResponse {
  public content: any = {};
  public _links: any[] = ["not init"];

  constructor(){
  }
}