<app-modern-sass-nav></app-modern-sass-nav>
<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="center-text">
                            <div class="">
                                <div class="header-text">
                                    <h1>Travel Find Me Pty Ltd</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="saas2-sub-text">Media  <span class="bold-text">Pack </span>
                                    </h3>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white">“We’d love to share the TFM story with you, from humble beginnings to well established business 
                                        friendships. Please click the arrow below or scroll down with your mouse to continue reading”
                                    </p>
                                </div>
                                <!-- <a class="btn btn-default primary-btn transparent">Read the whole story</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="center-text set-abs">
                        <img alt="" src="assets/images/saas2/aboutheader.png" class="img-fluid img-dextop">
                    </div>
                </div>
            </div>
  
            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->

<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container set-center-div">
        <div class="row">
            <div class="title">
                <div class="main-title">
                    <h2>Our Background Story</h2>
                </div>
                <div class="sub-title">
                    <p class="sub-title-para">Travel Find Me Pty Ltd. established in 2016 by CEO Hugo Venter and his partner then Charl Cillie. In 2019 Hugo acquired full ownership for TFM.
                        We are a company that started as a side business because we want to advance the travel industry when it comes to the use of technology. We have since become a full-time operation working to "do away" with the separation between traveler and agent by ensuring the tools exist for a smoother booking and travel experience.
                        In 2020 the entire world of traveling came to an abrupt halt because of the COVID19 virus.
                        At the start of 2021 TFM decided to hire its first employee with the hope of launching a new rebranded corporate identity as well as a complete turnkey solution for the ever-evolving traveling industry.
                        After 6 years we feel we are ready to incorporate a fresh look into the company.
                        We believe that within the chaos, that the traveling industry as most other industries, experienced. After all this we can still embrace the traveling industry with modern technology and hope.
                        Travel Find Me identifies itself as a travel tech solutions company.                            
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container set-center-div">
        <div class="row">
            <div class="col-md-12">
                <div class="title">
                    <div class="main-title">
                        <h2>Meet our team</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="row event team-sec team-grid speaker set-relative">
            <div class="col-lg-10 offset-lg-1 speker-container" style="margin-bottom: 30px;">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-instagram center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-linkedin center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="e-name" style="margin-bottom: 0px;">Hugo Venter</h3>
                                <small>
                                    <h6 class="post">“Head honcho” (CEO)</h6>
                                </small>
                            </div>
                            <div class="col-12">
                                <h5 class="e-name"></h5>
                                <h6 class="post" style="margin-bottom: -25px;">Biography</h6>
                                <p class="team-para">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                    galley of type and scrambled it to make a type specimen book.
                                    <a href="Javascript:void(0)" (click)="openModal(modelContent1);">Read more</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
            <div class="col-lg-10 offset-lg-1 speker-container" style="margin-bottom: 30px;">
                <div class="text-center d-flex">
                    <div class="team-img">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-instagram center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-linkedin center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <div class="row">
                            <div class="col-12">
                                <h2 class="e-name" style="margin-bottom: 0px;">Antoinette Venter</h2>
                                <small>
                                    <h6 class="post">“Chief of Other” stuff no one wants to do (COO)</h6>
                                </small>
                            </div>
                            <div class="col-12">
                                <h5 class="e-name"></h5>
                                <h6 class="post" style="margin-bottom: -25px;">Biography</h6>
                                <p class="team-para">
                                    When I do look back at my life, working in the travel industry would not be such a far fetch. At the age of only a mere 3 weeks old, 
                                    I experienced my first cross boarder journey when we moved from my hometown in Namibia to the hustle and bustle of Gauteng in South 
                                    Africa.
                                    <a href="Javascript:void(0)" (click)="openModal(modelContent2);">Read more</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container set-center-div">
        <div class="row">
            <div class="title">
                <div class="main-title">
                    <h2>Our Logo</h2>
                </div>
                <div class="sub-title">
                    <p class="sub-title-para">The TFM Masterbrand or Corporate Logo comprises two elements, the logo title and the logo symbol.<br> 
                        The Logo title was purposely kept short to emphasize the simplicity of the solution. The modern look and gradient points to the change in time and 
                        technology as well as the flow of information between different entities. The Logo Symbol Consists of a location pin and magnifying glass that depicts 
                        our understanding of the search to find the perfect holiday while having vibrant colors to emphasize that fun and travel go together.                                                    
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <img src="assets/images/event/team.png" alt="" class="img-fluid">
            </div>
            <div class="col-3">
                <img src="assets/images/event/team.png" alt="" class="img-fluid">
            </div>
            <div class="col-3">
                <img src="assets/images/event/team.png" alt="" class="img-fluid">
            </div>
            <div class="col-3">
                <img src="assets/images/event/team.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>
</section>

<ng-template #modelContent1 let-modal>
    <div class="modal-header">
      <h4 class="modal-title">We are pleased to introduce</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <h1>Hugo Venter</h1>
                <h3>“Head honcho” (CEO)</h3>
            </div>
            <div class="col-6">
                <div class="socials-lists">
                    <ul class="socials-horizontal justify-content-center">
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-facebook center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-instagram center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-twitter center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-linkedin center-content" aria-hidden="true"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12">
                <h5 class="e-name"></h5>
                <h6 class="post">Biography</h6>
                <p class="team-para">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen book.
                </p>
                <p class="team-para">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen book.
                </p>
                <div class="row">
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<ng-template #modelContent2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title">We are pleased to introduce</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <h1>ANTOINETTE VENTER</h1>
                <h3>“Chief Of Other” Stuff No One Wants To Do (COO)</h3>
            </div>
            <div class="col-6">
                <div class="socials-lists">
                    <ul class="socials-horizontal justify-content-center">
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-facebook center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-instagram center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-twitter center-content" aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href="javascript:void(0)"><i class="fa fa-linkedin center-content" aria-hidden="true"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12">
                <h5 class="e-name"></h5>
                <h6 class="post">Biography</h6>
                <p class="team-para">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen book.
                </p>
                <p class="team-para">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen book.
                </p>
                <div class="row">
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-3">
                        <img src="assets/images/event/team.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>
<!--feature css end-->

<!-- <app-modern-sass-screenshots></app-modern-sass-screenshots> -->
<!-- <app-modern-sass-services></app-modern-sass-services> -->
<app-modern-sass-brand></app-modern-sass-brand>
<!-- <app-modern-sass-testimonial></app-modern-sass-testimonial> -->
<!-- <app-modern-sass-pricing></app-modern-sass-pricing> -->
<app-modern-sass-footer></app-modern-sass-footer>

