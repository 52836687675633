<app-modern-sass-nav></app-modern-sass-nav>
<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div class="bg saas2-bg"  [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="center-text">
                            <div class="">
                                <div class="header-text">
                                    <h1>Getting Started</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h3 class="saas2-sub-text">with the <span class="bold-text">Travel Find Me Platform</span>
                                    </h3>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white">We are ecstatic that you are considering the use of the Travel Find Me platform
                                        to start, or expand, your travel business. Our platform is easy to use and with the help of our dedicated
                                        technical team integrating you website with the platform can be done in a jiffy.<br><br>
                                        <!-- Watch the short video to see the TFM platform in action! -->
                                    </p>
                                </div>
                                <!-- <a class="btn btn-default primary-btn transparent" routerLink="/getting-started">start now</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="center-text set-abs">
                        <img alt="" src="assets/images/saas2/gettingstartedheader.png" class="img-fluid img-dextop">
                    </div>
                </div>
            </div>
  
            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"  [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->
  
<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Key Aspects to connect to the TFM platform</h2>
                    </div>
                    <div class="sub-title">
                        <p class="sub-title-para">There are a few aspects you need to consider in order for you to
                            decide which of the TFM service packages you'd like to make use of.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-md-6">
                <div class="center-text justify-content-center">
                    <div class="image-container">
                        <img src="assets/images/saas2/advance-feature/01.png" alt=""
                             class="img-fluid advance-feature-img">
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-md-6">
                <div *ngFor="let data of features">
                    <div class="center-content justify-content-start">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="feature-content">
                            <h5 class="feature-head">{{data.title}}</h5>
                            <p class="feature-para">{{data.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature css end-->

<!-- <app-modern-sass-screenshots></app-modern-sass-screenshots> -->
<!-- <app-modern-sass-services></app-modern-sass-services>
<app-modern-sass-business></app-modern-sass-business>
<app-modern-sass-brand></app-modern-sass-brand> -->
<!-- <app-modern-sass-testimonial></app-modern-sass-testimonial> -->
<app-modern-sass-pricing></app-modern-sass-pricing>
<app-modern-sass-footer></app-modern-sass-footer>