<!--footer start-->
<footer class="saas2 footer2" id="contact" [ngStyle]="{'background-image': 'url(assets/images/saas2/footer.png)', 'background-repeat': 'no-repeat', 'background-position': 'top'}">
    <div class="container footer-padding">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="logo-sec">
                    <div class="footer-title mobile-title p-t-0">
                        <h3 class="text-white">About Us</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/smallwhite.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Travel Find Me (Pty) Ltd</h6>
                            <h6 class="text-white para-address">Cape Town, </h6>
                            <h6 class="text-white para-address">South Africa</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="https://www.facebook.com/travelfind.me"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="https://www.instagram.com/travelfind.me/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="https://www.linkedin.com/company/travelfindme/"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Services</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class="">
                                <a routerLink="/starter">TFM Starter</a>
                            </li>
                            <li class="">
                                <a routerLink="/premium">TFM Premium</a>
                            </li>
                            <li class="">
                                <a href="#">Partnerships (info coming soon)</a>
                            </li>
                            <!-- <li class="">
                                <a href="#">Discover Features</a>
                            </li> -->
                            <li class="">
                                <a routerLink="/affiliate">Affiliate Program</a>
                            </li>
                            <li class="">
                                <a routerLink="/contact">Request a demo</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Company</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Company</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class="">
                                <a routerLink="/getting-started">Getting started</a>
                            </li>
                            <li class="">
                                <a routerLink="/about">About us</a>
                            </li>
                            <li class="">
                                <a routerLink="/contact">Contact us</a>
                            </li>
                            <!-- <li class="">
                                <a routerLink="/media-pack">Media Pack</a>
                            </li>
                            <li class="">
                                <a href="#">About security</a>
                            </li>
                            <li class="">
                                <a href="#">Terms of services</a>
                            </li>
                            <li class="">
                                <a href="#">Privacy policy</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Subscribe</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Subscribe</h5>
                    <div class="form-group">
                        <input #email type="email" name="EMAIL" class="form-control" id="mce-EMAIL" placeholder="email address" />
                        <a href="javascript:void();" (click)="subscribe(email.value)"><i class="fa fa-paper-plane" aria-hidden="true"></i></a>
                        <div class="text-warning" *ngIf="formStatus.error">{{formStatus.message}}</div>
                        <div class="text-success" *ngIf="formStatus.success">{{formStatus.message}}</div>
                        
                    </div>
                    <div>
                        <h5 class="text-white unice-text">Travel Find Me (Pty) Ltd</h5>
                        <h6 class="text-white unice-copyright-text">© 2022</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>