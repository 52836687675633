import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-modern-sass-footer',
  templateUrl: './modern-sass-footer.component.html',
  styleUrls: ['./modern-sass-footer.component.scss']
})
export class ModernSassFooterComponent implements OnInit {

  public formStatus : any = {
    message:"",
    errors:false,
    success:false
  };

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  subscribe(email) {
    this.formStatus.message = "";
    this.formStatus.success = false;
    this.formStatus.error = false;

    var url = "https://travelfind.us15.list-manage.com/subscribe/post-json?u=7777306b0f923b7ebc32e461d&id=73c2b5d5ab&EMAIL="+email+"&c=JSONP_CALLBACK";

    this.http.jsonp(url, 'callback')
     .subscribe((res:any) => {
       if (res.result == "error") {
        this.formStatus.error = true;
        this.formStatus.message = "You are already subscribed!"
       }
       else {
        this.formStatus.success = true;
        this.formStatus.message = "Great!  Chat soon!"
       }
     });
  }
}
