import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-pricing',
  templateUrl: './modern-sass-pricing.component.html',
  styleUrls: ['./modern-sass-pricing.component.scss']
})
export class ModernSassPricingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  pricing = [
    { 
      img:"assets/images/yoga-img/abs-yoga.png",
      package:"Starter",
      feature1:"Integration with TFM platform",
      feature2:"Manage Travel Packages",
      feature3:"Manage Customers/clients",
      feature4:"Enquiries via your new website",
      feature5:"Track progress of bookings",
      price:"2,495.00",
      note:"Once-off setup fee of R9,995.00",
      btn:"More info"
    },
    {
      img:"assets/images/yoga-img/abs-yoga.png",
      package:"Premium",
      feature1:"Integration with TFM platform",
      feature2:"Manage Travel Packages",
      feature3:"Manage Customers/clients",
      feature4:"Booking via your new website",
      feature5:"Track progress of bookings",
      feature6:"Accept real time bookings",
      feature7:"Multiple service APIs",
      price:"9,995.00",
      note:"Once-off setup fee of 24,995.00",
      btn:"Enquire"
    },
    {
      img:"assets/images/yoga-img/abs-yoga.png",
      package:"Partnership",
      feature1:"Integration with TFM platform",
      feature2:"Manage Travel Packages",
      feature3:"Manage Customers/clients",
      feature4:"Booking via your new website",
      feature5:"Track progress of bookings",
      feature6:"Accept real time bookings",
      feature7:"Multiple service APIs",
      feature8:"Own supplier API integration",
      price:"POR",
      btn:"Enquire"
    }
  ]
  
  pricingcarouselOptions= {
    items: 3,
    nav: false,
    dots:true,
    autoplay: false,
    center: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        992: {
            items: 3
        }
    }
  }
}
