import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-sass-feature',
  templateUrl: './modern-sass-feature.component.html',
  styleUrls: ['./modern-sass-feature.component.scss']
})
export class ModernSassFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

features = [
  {
    img:'assets/images/saas2/advance-feature/1.png',
    title: 'Website Integration',
    description: 'Connect your current website with our platform or let us help you spin up your own travel portal'
  },
  {
    img:'assets/images/saas2/advance-feature/2.png',
    title: 'Online Reservation System',
    description: 'Get access to a cloud based reservation system, ready to assist you in making bookings for your clients'
  },
  {
    img:'assets/images/saas2/advance-feature/3.png',
    title: 'Various Supplier APIs.',
    description: 'Enjoy a multitude of travel destination options with integration into various supplier APIs.'
  },
  {
    img:'assets/images/saas2/advance-feature/5.png',
    title: 'Mobile App - Coming Soon',
    description: 'A mobile app for real-time management and notifications.'
  }
]
}
